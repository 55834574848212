// /**
//  * Reset some basic elements
//  */
// body, h1, h2, h3, h4, h5, h6,
// p, blockquote, pre, hr,
// dl, dd, ol, ul, figure {
//   margin: 0;
//   padding: 0; }

// /**
//  * Basic styling
//  */
// body {
//   font: 400 16px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
//   color: #111;
//   background-color: #fdfdfd;
//   -webkit-text-size-adjust: 100%;
//   -webkit-font-feature-settings: "kern" 1;
//   -moz-font-feature-settings: "kern" 1;
//   -o-font-feature-settings: "kern" 1;
//   font-feature-settings: "kern" 1;
//   font-kerning: normal; }

// /**
//  * Set `margin-bottom` to maintain vertical rhythm
//  */
// h1, h2, h3, h4, h5, h6,
// p, blockquote, pre,
// ul, ol, dl, figure,
// .highlight {
//   margin-bottom: 15px; }

// /**
//  * Images
//  */
// img {
//   max-width: 100%;
//   vertical-align: middle; }

// /**
//  * Figures
//  */
// figure > img {
//   display: block; }

// figcaption {
//   font-size: 14px; }

// /**
//  * Lists
//  */
// ul, ol {
//   margin-left: 30px; }

// li > ul,
// li > ol {
//   margin-bottom: 0; }

// /**
//  * Headings
//  */
// h1, h2, h3, h4, h5, h6 {
//   font-weight: 400; }

// /**
//  * Links
//  */
// a {
//   color: #2a7ae2;
//   text-decoration: none; }
//   a:visited {
//     color: #1756a9; }
//   a:hover {
//     color: #111;
//     text-decoration: underline; }

// /**
//  * Blockquotes
//  */
// blockquote {
//   color: #828282;
//   border-left: 4px solid #e8e8e8;
//   padding-left: 15px;
//   font-size: 18px;
//   letter-spacing: -1px;
//   font-style: italic; }
//   blockquote > :last-child {
//     margin-bottom: 0; }

// /**
//  * Code formatting
//  */
// pre,
// code {
//   font-size: 15px;
//   border: 1px solid #e8e8e8;
//   border-radius: 3px;
//   background-color: #eef; }

// code {
//   padding: 1px 5px; }

// pre {
//   padding: 8px 12px;
//   overflow-x: auto; }
//   pre > code {
//     border: 0;
//     padding-right: 0;
//     padding-left: 0; }

// /**
//  * Wrapper
//  */
// .wrapper {
//   max-width: -webkit-calc(800px - (30px * 2));
//   max-width: calc(800px - (30px * 2));
//   margin-right: auto;
//   margin-left: auto;
//   padding-right: 30px;
//   padding-left: 30px; }
//   @media screen and (max-width: 800px) {
//     .wrapper {
//       max-width: -webkit-calc(800px - (30px));
//       max-width: calc(800px - (30px));
//       padding-right: 15px;
//       padding-left: 15px; } }

// /**
//  * Clearfix
//  */
// .wrapper:after, .footer-col-wrapper:after {
//   content: "";
//   display: table;
//   clear: both; }

// /**
//  * Icons
//  */
// .icon > svg {
//   display: inline-block;
//   width: 16px;
//   height: 16px;
//   vertical-align: middle; }
//   .icon > svg path {
//     fill: #828282; }

// /**
//  * Site header
//  */
// .site-header {
//   border-top: 5px solid #424242;
//   border-bottom: 1px solid #e8e8e8;
//   min-height: 56px;
//   position: relative; }

// .site-title {
//   font-size: 26px;
//   font-weight: 300;
//   line-height: 56px;
//   letter-spacing: -1px;
//   margin-bottom: 0;
//   float: left; }
//   .site-title, .site-title:visited {
//     color: #424242; }

// .site-nav {
//   float: right;
//   line-height: 56px; }
//   .site-nav .menu-icon {
//     display: none; }
//   .site-nav .page-link {
//     color: #111;
//     line-height: 1.5; }
//     .site-nav .page-link:not(:last-child) {
//       margin-right: 20px; }
//   @media screen and (max-width: 600px) {
//     .site-nav {
//       position: absolute;
//       top: 9px;
//       right: 15px;
//       background-color: #fdfdfd;
//       border: 1px solid #e8e8e8;
//       border-radius: 5px;
//       text-align: right; }
//       .site-nav .menu-icon {
//         display: block;
//         float: right;
//         width: 36px;
//         height: 26px;
//         line-height: 0;
//         padding-top: 10px;
//         text-align: center; }
//         .site-nav .menu-icon > svg {
//           width: 18px;
//           height: 15px; }
//           .site-nav .menu-icon > svg path {
//             fill: #424242; }
//       .site-nav .trigger {
//         clear: both;
//         display: none; }
//       .site-nav:hover .trigger {
//         display: block;
//         padding-bottom: 5px; }
//       .site-nav .page-link {
//         display: block;
//         padding: 5px 10px;
//         margin-left: 20px; }
//         .site-nav .page-link:not(:last-child) {
//           margin-right: 0; } }

// /**
//  * Site footer
//  */
// .site-footer {
//   border-top: 1px solid #e8e8e8;
//   padding: 30px 0; }

// .footer-heading {
//   font-size: 18px;
//   margin-bottom: 15px; }

// .contact-list,
// .social-media-list {
//   list-style: none;
//   margin-left: 0; }

// .footer-col-wrapper {
//   font-size: 15px;
//   color: #828282;
//   margin-left: -15px; }

// .footer-col {
//   float: left;
//   margin-bottom: 15px;
//   padding-left: 15px; }

// .footer-col-1 {
//   width: -webkit-calc(35% - (30px / 2));
//   width: calc(35% - (30px / 2)); }

// .footer-col-2 {
//   width: -webkit-calc(20% - (30px / 2));
//   width: calc(20% - (30px / 2)); }

// .footer-col-3 {
//   width: -webkit-calc(45% - (30px / 2));
//   width: calc(45% - (30px / 2)); }

// @media screen and (max-width: 800px) {
//   .footer-col-1,
//   .footer-col-2 {
//     width: -webkit-calc(50% - (30px / 2));
//     width: calc(50% - (30px / 2)); }

//   .footer-col-3 {
//     width: -webkit-calc(100% - (30px / 2));
//     width: calc(100% - (30px / 2)); } }
// @media screen and (max-width: 600px) {
//   .footer-col {
//     float: none;
//     width: -webkit-calc(100% - (30px / 2));
//     width: calc(100% - (30px / 2)); } }
// /**
//  * Page content
//  */
// .page-content {
//   padding: 30px 0; }

// .page-heading {
//   font-size: 20px; }

// .post-list {
//   margin-left: 0;
//   list-style: none; }
//   .post-list > li {
//     margin-bottom: 30px; }

// .post-meta {
//   font-size: 14px;
//   color: #828282; }

// .post-link {
//   display: block;
//   font-size: 24px; }

// /**
//  * Posts
//  */
// .post-header {
//   margin-bottom: 30px; }

// .post-title {
//   font-size: 42px;
//   letter-spacing: -1px;
//   line-height: 1; }
//   @media screen and (max-width: 800px) {
//     .post-title {
//       font-size: 36px; } }

// .post-content {
//   margin-bottom: 30px; }
//   .post-content h2 {
//     font-size: 32px; }
//     @media screen and (max-width: 800px) {
//       .post-content h2 {
//         font-size: 28px; } }
//   .post-content h3 {
//     font-size: 26px; }
//     @media screen and (max-width: 800px) {
//       .post-content h3 {
//         font-size: 22px; } }
//   .post-content h4 {
//     font-size: 20px; }
//     @media screen and (max-width: 800px) {
//       .post-content h4 {
//         font-size: 18px; } }

// /**
//  * Syntax highlighting styles
//  */
// .highlight {
//   background: #fff; }
//   .highlighter-rouge .highlight {
//     background: #eef; }
//   .highlight .c {
//     color: #998;
//     font-style: italic; }
//   .highlight .err {
//     color: #a61717;
//     background-color: #e3d2d2; }
//   .highlight .k {
//     font-weight: bold; }
//   .highlight .o {
//     font-weight: bold; }
//   .highlight .cm {
//     color: #998;
//     font-style: italic; }
//   .highlight .cp {
//     color: #999;
//     font-weight: bold; }
//   .highlight .c1 {
//     color: #998;
//     font-style: italic; }
//   .highlight .cs {
//     color: #999;
//     font-weight: bold;
//     font-style: italic; }
//   .highlight .gd {
//     color: #000;
//     background-color: #fdd; }
//   .highlight .gd .x {
//     color: #000;
//     background-color: #faa; }
//   .highlight .ge {
//     font-style: italic; }
//   .highlight .gr {
//     color: #a00; }
//   .highlight .gh {
//     color: #999; }
//   .highlight .gi {
//     color: #000;
//     background-color: #dfd; }
//   .highlight .gi .x {
//     color: #000;
//     background-color: #afa; }
//   .highlight .go {
//     color: #888; }
//   .highlight .gp {
//     color: #555; }
//   .highlight .gs {
//     font-weight: bold; }
//   .highlight .gu {
//     color: #aaa; }
//   .highlight .gt {
//     color: #a00; }
//   .highlight .kc {
//     font-weight: bold; }
//   .highlight .kd {
//     font-weight: bold; }
//   .highlight .kp {
//     font-weight: bold; }
//   .highlight .kr {
//     font-weight: bold; }
//   .highlight .kt {
//     color: #458;
//     font-weight: bold; }
//   .highlight .m {
//     color: #099; }
//   .highlight .s {
//     color: #d14; }
//   .highlight .na {
//     color: #008080; }
//   .highlight .nb {
//     color: #0086B3; }
//   .highlight .nc {
//     color: #458;
//     font-weight: bold; }
//   .highlight .no {
//     color: #008080; }
//   .highlight .ni {
//     color: #800080; }
//   .highlight .ne {
//     color: #900;
//     font-weight: bold; }
//   .highlight .nf {
//     color: #900;
//     font-weight: bold; }
//   .highlight .nn {
//     color: #555; }
//   .highlight .nt {
//     color: #000080; }
//   .highlight .nv {
//     color: #008080; }
//   .highlight .ow {
//     font-weight: bold; }
//   .highlight .w {
//     color: #bbb; }
//   .highlight .mf {
//     color: #099; }
//   .highlight .mh {
//     color: #099; }
//   .highlight .mi {
//     color: #099; }
//   .highlight .mo {
//     color: #099; }
//   .highlight .sb {
//     color: #d14; }
//   .highlight .sc {
//     color: #d14; }
//   .highlight .sd {
//     color: #d14; }
//   .highlight .s2 {
//     color: #d14; }
//   .highlight .se {
//     color: #d14; }
//   .highlight .sh {
//     color: #d14; }
//   .highlight .si {
//     color: #d14; }
//   .highlight .sx {
//     color: #d14; }
//   .highlight .sr {
//     color: #009926; }
//   .highlight .s1 {
//     color: #d14; }
//   .highlight .ss {
//     color: #990073; }
//   .highlight .bp {
//     color: #999; }
//   .highlight .vc {
//     color: #008080; }
//   .highlight .vg {
//     color: #008080; }
//   .highlight .vi {
//     color: #008080; }
//   .highlight .il {
//     color: #099; }